<template>
  <Loading v-if="initLoading" />
  <div v-else>
    <Header>合成详情</Header>
    <div class="content">
      <div class="head">
        <img class="img" :src="detail.goods.showPic" alt="" />
        <div class="hcsb">
          <img src="../../../assets/images/hcsb.png" alt="" />
        </div>
      </div>
      <div class="title">{{ detail.goods.name }}</div>
      <div class="line"></div>
      <!-- <div class="key">
        <div class="text">消耗钥匙个数：</div>
        <div class="num">{{ detail.haveQ || 0 }}个</div>
      </div> -->
      <div class="science">使用的藏品材料：</div>
      <div class="list">
        <div class="bg" v-for="item in detail.haveGoodList" :key="item.id">
          <div class="item">
            <div class="left">
              <img :src="item.listPic" alt="" />
            </div>
            <div class="middle">
              <div class="title">
                <span v-if="item.goodsNumber.length === 1">{{
                  item.goodsNumber[0]
                }}</span>
                <span v-else>{{ item.name }}</span>
              </div>
            </div>
            <div
              v-if="goodsNumber.length > 1"
              class="right"
              @click="
                goodsNumber = item.goodsNumber;
                showList = true;
              "
            >
              展开全部 <i></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 展开 -->
    <div class="commodityList" v-if="showList" @click="showList = false">
      <div class="wrapper" @click.stop>
        <div class="block">
          <div class="product">
            <div>
              <div class="checkedbox">
                <div
                  style="font-size: 16px; font-weight: 600; margin-right: 10px"
                >
                  数量: {{ goodsNumber.length }}
                </div>
              </div>
            </div>
            <div
              @click="showList = false"
              style="color: #2376ff; font-size: 14px"
            >
              收起
            </div>
          </div>

          <div style="overflow: auto; margin-top: 10px">
            <div class="product_list" v-for="item in goodsNumber" :key="item">
              <div class="product_num">
                <div>{{ item }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      detail: { goods: {}, haveGoodList: [] },
      showList: false,
      goodsNumber: [],
      initLoading: true,
    };
  },
  mounted() {
    this.initLoading = true;
    this.getUserComposeLogInfo();
  },
  methods: {
    async getUserComposeLogInfo() {
      try {
        const data = await this.$api.getUserComposeLogInfo({
          id: this.$route.query.id,
          token: localStorage.getItem('token'),
        });
        if (data.code === 0) {
          this.detail = data.data || { goods: {}, haveGoodList: [] };
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.initLoading = false;
      }
    },
  },
};
</script>

<style scoped lang="less">
.content {
  .head {
    position: relative;
    .img {
      max-width: 100%;
      vertical-align: middle;
    }
    .hcsb {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 50%;
      }
    }
  }
  .title {
    font-size: 14px;
    font-weight: 600;
    color: #010000;
    padding: 10px 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .line {
    height: 10px;
    background: #f8f8f8;
  }
  .key {
    font-size: 14px;
    color: #666666;
    display: flex;
    align-items: flex-end;
    padding: 10px 20px 5px;
    .num {
      font-size: 16px;
      font-weight: 600;
      color: #000;
    }
  }
  .science {
    padding: 18px 20px 0;
    font-size: 14px;
    font-weight: 600;
    color: #000;
  }
  .list {
    padding: 20px;
    .bg {
      background: #f8f8f8;
      padding: 15px;
      border-radius: 5px;
      box-sizing: border-box;
      margin-bottom: 20px;
      .item {
        display: flex;
        align-items: center;
        .left {
          img {
            width: 70px;
            vertical-align: middle;
          }
        }
        .middle {
          padding-left: 10px;
          flex: 1;
          .title {
            font-size: 14px;
            font-weight: 600;
            padding: 0;
            color: #000000;
          }
          .time {
            font-size: 12px;
            margin-top: 10px;
            color: #a4a5a9;
          }
        }
        .right {
          font-size: 14px;
          color: #2376ff;
          display: flex;
          align-items: center;
          i {
            display: block;
            width: 8px;
            height: 8px;
            border-top: 2px solid #2376ff;
            border-right: 2px solid #2376ff;
            transform: rotate(45deg);
            margin-left: 3px;
          }
        }
      }
    }
  }
}

.commodityList {
  z-index: 99;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.6);
  .wrapper {
    display: flex;
    // align-items: center;
    justify-content: center;
    height: 100%;
  }
  .block {
    width: 100%;
    position: fixed;
    bottom: 0;
    height: 50%;
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding: 0;
  }
  .product {
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
    align-items: center;
    img {
      width: 12px;
      height: 12px;
    }
  }
  .checkedbox {
    align-items: center;
    display: flex;
    font-size: 12px;
    align-items: center;
    justify-content: space-between;
  }
  .checkbox {
    display: flex;
    padding-right: 10px;
    div.checkBoxChild {
      margin: 0 10px;
      display: flex;
      align-items: center;
      i {
        display: flex;
        flex-direction: column;
        margin: 3px 0;
        width: 10px;
        height: 10px;
        margin-left: 5px;
        &.just {
          &::after {
            display: block;
            content: '';
            border-top: 5px solid transparent;
            border-bottom: 5px solid #ddd;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
          }
          &.active {
            &.just {
              &::after {
                border-bottom: 5px solid #333;
              }
            }
          }
        }
        &.inverted {
          &::after {
            display: block;
            content: '';
            border-top: 5px solid #ddd;
            border-bottom: 5px solid transparent;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
          }
          &.active {
            &.inverted {
              &::after {
                border-top: 5px solid #333;
              }
            }
          }
        }
      }
    }
  }
  .product_list {
    display: flex;
    font-size: 16px;
    line-height: 40px;
    justify-content: space-between;
    margin-right: 20px;
    .product_text {
      display: flex;
      align-items: center;
    }
    .product_num {
      margin-left: 10px;
      display: flex;
      font-size: 12px;
    }
    .num_several {
      margin-left: 5px;
      font-size: 10px;
      color: #aaa;
      &.active {
        color: #fab878;
        span {
          padding: 3px;
        }
      }
      &.unActive {
        color: #cecece;
        span {
          background: #f8f8f8;
          padding: 3px;
        }
      }
      &.lock {
        color: #009944;
        span {
          background: #deffee;
          padding: 3px;
        }
      }
    }
    .product_img {
      width: 4px;
      height: 6px;
      margin-left: 5px;
    }
  }
  .even {
    font-size: 12px;
    color: #abaab9;
    background-color: #abaab957;
    width: 70px;
    text-align: center;
    border-radius: 10px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    padding-left: 15px;
    img {
      width: 6px;
      height: 4px;
    }
  }
}
</style>
